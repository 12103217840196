/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  AvatarLarge,
  AvatarMedium,
  ExternalLink,
  InlineTextButton,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import CustomLinksMenu from '../TopbarDesktop/CustomLinksMenu/CustomLinksMenu';
import { isArrayLength } from '../../../../util/genericHelpers';

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    intl,
    config,
  } = props;

  const user = ensureCurrentUser(currentUser);

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />
          </div>

          <div className={css.customLinksWrapper}>{extraLinks}</div>

          <div className={css.spacer} />
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const [mounted, setMounted] = useState(false);
  const [DropdownComponent, setDropdownComponent] = useState(null);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  useEffect(() => {
    setMounted(true);
  }, []);
  useEffect(() => {
    // Dynamically import the DropdownComponent component
    const loadDropdown = async () => {
      const { default: DropdownComponent } = await import('react-multilevel-dropdown');
      // Optionally, import CSS here if you find a way to handle it, or ensure it's imported elsewhere in a way that's compatible with your setup
      setDropdownComponent(() => DropdownComponent); // Use a function to set state when the new state depends on the previous state
    };

    loadDropdown();
  }, []); // Empty dependency array means this runs once on mount
  const generateDropdownItems = DropdownComponent
    ? (options, history, queryParam) => {
        return isArrayLength(options) ? (
          options.map(o => (
            <DropdownComponent.Item onClick={() => history.push(`/s?${queryParam}=${o?.option}`)}>
              {o?.label}
            </DropdownComponent.Item>
          ))
        ) : (
          <DropdownComponent.Item></DropdownComponent.Item>
        );
      }
    : () => {};

  const listingFields = config?.listing?.listingFields;
  const academicUniformOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'academic_uniform')?.enumOptions
    : [];

  const cocurricularOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'school_uniform_category')?.enumOptions
    : [];

  const corePEUniformOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'core_pe_uniform')?.enumOptions
    : [];

  const sportingUniformsOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'sporting_uniforms')?.enumOptions
    : [];

  const novelsOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'text_books_novels')?.enumOptions
    : [];

  const booksSubjectAreaOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'text_book_subject_area')?.enumOptions
    : [];

  const equimentCategoryOptions = isArrayLength(listingFields)
    ? listingFields.find(l => l?.key === 'school_equipment_category')?.enumOptions
    : [];
  const renderMultiLevelDropdown = DropdownComponent ? (
    <>
      <DropdownComponent
        buttonClassName={css.multiMenuButton}
        wrapperClassName={css.multiMenuLink}
        menuClassName={css.dropdownMenu}
        position="left"
        title="School Uniform"
      >
        <DropdownComponent.Item>
          Academic Uniform
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(academicUniformOptions, history, 'pub_academic_uniform')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
        <DropdownComponent.Item>
          Co-Curricular Uniforms
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(cocurricularOptions, history, 'pub_cocurricular_uniforms')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
        <DropdownComponent.Item>
          Core PE Uniform
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(corePEUniformOptions, history, 'pub_core_pe_uniform')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
        <DropdownComponent.Item>
          Sporting Uniforms
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(sportingUniformsOptions, history, 'pub_sporting_uniforms')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
      </DropdownComponent>
      <DropdownComponent
        buttonClassName={css.multiMenuButton}
        wrapperClassName={css.multiMenuLink}
        menuClassName={css.dropdownMenu}
        position="left"
        title="School Text Books"
      >
        <DropdownComponent.Item>Educational Books</DropdownComponent.Item>
        <DropdownComponent.Item>
          Novels & Biographies
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(novelsOptions, history, 'pub_school_text_book_category')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
        <DropdownComponent.Item>
          Text Books
          <DropdownComponent.Submenu className={css.submenu} position="right">
            {generateDropdownItems(booksSubjectAreaOptions, history, 'pub_text_book_subject_area')}
          </DropdownComponent.Submenu>
        </DropdownComponent.Item>
      </DropdownComponent>
      <DropdownComponent
        buttonClassName={css.multiMenuButton}
        wrapperClassName={css.multiMenuLink}
        menuClassName={css.dropdownMenu}
        position="left"
        title="School Equipment"
      >
        {generateDropdownItems(equimentCategoryOptions, history, 'pub_school_equipment_category')}
      </DropdownComponent>
    </>
  ) : null;
  return (
    <div className={css.root}>
      <AvatarMedium className={css.avatar} user={currentUser} />
      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={css.accountLinksWrapper}>
          {renderMultiLevelDropdown}
          <CustomLinksMenu
            currentPage={currentPage}
            customLinks={customLinks}
            intl={intl}
            hasClientSideContentReady={authenticatedOnClientSide || !isAuthenticatedOrJustHydrated}
            customClassName={css.navLink}
          />
          <NamedLink
            className={classNames(css.inbox, currentPageClass(`InboxPage:${inboxTab}`))}
            name="InboxPage"
            params={{ tab: inboxTab }}
          >
            <FormattedMessage id="TopbarMobileMenu.inboxLink" />
            {notificationCountBadge}
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
          </NamedLink>
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
          </NamedLink>
        </div>
        <div className={css.customLinksWrapper}>{extraLinks}</div>
        <div className={css.spacer} />
      </div>
      <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div>
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
